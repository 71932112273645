import { ViewportScroller } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppFeature, EventTrackingService } from '@hyundai/ng-common-lib';
import { Store } from '@ngrx/store';
import { environment } from '../environments/environment';

@Component({
    standalone: true,
    selector: 'app-root',
    templateUrl: './app.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [RouterOutlet],
})
export class AppComponent {
    constructor() {
        inject(ViewportScroller).setOffset([0, 50]);
        inject(Store).dispatch(AppFeature.actions.initPage({ isE2e: environment.e2e ?? false }));
        inject(EventTrackingService).init(environment.logs);
    }
}
